import React, { Suspense } from 'react';
import { t } from '@lingui/macro';
import { Switch, Route } from 'react-router-dom';
import Error404 from 'assets/Error404.png';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { PublicRoute } from 'components/PublicRoute';
import { AuthRoute } from 'components/AuthRoute';
import { Fallback, withSuspense } from 'components/Fallback';
import { Routes } from 'constants/';

const Landing = React.lazy(() =>
  import('pages/Landing').then((module) => ({ default: module.Landing }))
);
const SignIn = React.lazy(() =>
  import('pages/SignIn').then((module) => ({ default: module.SignIn }))
);
const SignUp = React.lazy(() =>
  import('pages/SignUp').then((module) => ({ default: module.SignUp }))
);
const RecoverPassword = React.lazy(() =>
  import('pages/RecoverPassword').then((module) => ({
    default: module.RecoverPassword,
  }))
);
const ResetPassword = React.lazy(() =>
  import('pages/ResetPassword').then((module) => ({
    default: module.ResetPassword,
  }))
);
const Error = React.lazy(() =>
  import('pages/Error').then((module) => ({
    default: module.Error,
  }))
);
const PrivacyPolicy = React.lazy(() =>
  import('pages/PrivacyPolicy').then((module) => ({
    default: module.PrivacyPolicy,
  }))
);
const TermsOfService = React.lazy(() =>
  import('pages/TermsOfService').then((module) => ({
    default: module.TermsOfService,
  }))
);
const Dashboard = React.lazy(() =>
  import('pages/Dashboard').then((module) => ({
    default: module.Dashboard,
  }))
);
const SupplierStats = React.lazy(() =>
  import('pages/SupplierStats').then((module) => ({
    default: module.SupplierStats,
  }))
);
const Performance = React.lazy(() =>
  import('pages/Performance').then((module) => ({
    default: module.Performance,
  }))
);
const ContactUs = React.lazy(() =>
  import('pages/ContactUs').then((module) => ({
    default: module.ContactUs,
  }))
);
const Payouts = React.lazy(() =>
  import('pages/Payouts').then((module) => ({
    default: module.Payouts,
  }))
);
const VerifyEmail = React.lazy(() =>
  import('pages/VerifyEmail').then((module) => ({
    default: module.VerifyEmail,
  }))
);
const Settings = React.lazy(() =>
  import('pages/Settings').then((module) => ({
    default: module.Settings,
  }))
);
const Commitment = React.lazy(() =>
  import('pages/Commitment').then((module) => ({
    default: module.Commitment,
  }))
);
const Profilers = React.lazy(() =>
  import('pages/Profilers').then((module) => ({
    default: module.Profilers,
  }))
);
const Onboarding = React.lazy(() =>
  import('pages/Onboarding').then((module) => ({
    default: module.Onboarding,
  }))
);
const Supplier = React.lazy(() =>
  import('pages/Supplier').then((module) => ({
    default: module.Supplier,
  }))
);

export const App: React.FC = () => (
  <Suspense fallback={<Fallback />}>
    <Switch>
      <PublicRoute path={Routes.Landing} exact component={Landing} />
      <AuthRoute path={Routes.SignIn} exact component={SignIn} />
      <AuthRoute path={Routes.SignUp} exact component={SignUp} />
      <Route path={Routes.RecoverPassword} exact component={RecoverPassword} />
      <Route path={Routes.ResetPassword} exact component={ResetPassword} />
      <Route path={Routes.PrivacyPolicy} exact component={PrivacyPolicy} />
      <Route path={Routes.TermsOfUse} exact component={TermsOfService} />

      <ProtectedRoute
        path={Routes.Dashboard}
        exact
        component={withSuspense(Dashboard)}
      />
      <ProtectedRoute
        path={Routes.Performance}
        exact
        component={withSuspense(Performance)}
      />
      <ProtectedRoute
        path={Routes.SupplierStats}
        exact
        component={withSuspense(SupplierStats)}
      />

      <ProtectedRoute
        path={Routes.ContactUs}
        exact
        component={withSuspense(ContactUs)}
      />
      <ProtectedRoute
        path={`${Routes.Payouts}/:handler?`}
        exact
        component={withSuspense(Payouts)}
      />
      <ProtectedRoute
        path={Routes.VerifyEmail}
        exact
        component={withSuspense(VerifyEmail)}
      />
      <ProtectedRoute
        path={`${Routes.Settings}/:tab?`}
        exact
        component={withSuspense(Settings)}
      />

      <ProtectedRoute path={Routes.Onboarding} exact component={Onboarding} />
      <ProtectedRoute path={Routes.Profilers} exact component={Profilers} />
      <ProtectedRoute
        path={`${Routes.Commitment}/:survey_provider/:outcome?`}
        exact
        component={Commitment}
      />

      <Route path={Routes.Supplier} exact component={Supplier} />

      <Route path={Routes.ErrorPage} exact component={Error} />
      <Route
        path={Routes.PageNotFound}
        exact
        render={() => (
          <Error
            title={t`Page not found`}
            description={t`Sorry, the page you are looking for doesn’t exist or has been moved.`}
            image={Error404}
          />
        )}
      />
    </Switch>
  </Suspense>
);
