import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { baseApi } from './api/baseApi';
import { ErrorHandlerMiddleware } from './middleware';
import { authSlice, errorSlice } from './slices';
import { researchDefenderApi } from './api';

const appReducer = combineReducers({
  authStore: authSlice.reducer,
  errorStore: errorSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [researchDefenderApi.reducerPath]: researchDefenderApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (
    (action.type === 'baseApi/executeMutation/fulfilled' &&
      action?.meta?.arg?.endpointName === 'logOut') ||
    action.type === 'auth/logout'
  ) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      baseApi.middleware,
      researchDefenderApi.middleware,
      ErrorHandlerMiddleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
