export const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const getMonthSize = (month: number, year: number) => {
  const monthLength = monthDays.slice();

  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  return monthLength[month - 1];
};
