export enum Routes {
  Landing = '/',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  RecoverPassword = '/recover',
  ResetPassword = '/reset-password',
  Dashboard = '/dashboard',
  SupplierStats = '/supplier-stats',
  ContactUs = '/contact-us',
  Performance = '/performance',
  Settings = '/settings',
  Payouts = '/payouts',
  VerifyEmail = '/verify-email',
  PageNotFound = '*',
  ErrorPage = '/500',
  PrivacyPolicy = '/privacy-policy',
  TermsOfUse = '/terms-of-use',
  Commitment = '/return_from_study',
  Profilers = '/survey',
  Onboarding = '/welcome',
  Supplier = '/begin',
}

export enum APIRoutes {
  LogIn = '/api/login/',
  LogOut = '/api/logout/',
  SingUp = '/api/signup/',
  ResetPasswordInitiate = '/api/reset_password/initiate/',
  ResetPasswordSubmit = '/api/reset_password/submit/',
  RefreshTokens = '/api/refresh_tokens/',

  UserInfoBasic = '/api/user/basic/',
  UserInfoFull = '/api/user/full/',
  ChangePassword = '/api/user/password/',
  VerifyEmailInitiate = '/api/verify_email/initiate/',
  VerifyEmailSubmit = '/api/verify_email/submit/',
  UserAddress = '/api/user/address/',
  UserPhone = '/api/user/phone/',
  DeleteAccount = '/api/user/',
  Preferences = '/api/user/preferences/',
  ContactUs = '/api/contact_us/',
  Countries = '/api/countries/',

  PayoutsHistory = '/api/user/payouts/',
  CreatePayout = '/api/payout/',
  PayoutMethods = '/api/user/payout_methods/',
  PayoutHandlers = '/api/user/payout_handlers/',
  SupportedPayoutMethods = '/api/payout/supported_methods/',
  ConnectPaypal = '/api/paypal/connect/',
  DisconnectPaypal = '/api/paypal/',

  SurveysHistory = '/api/user/commitments/',
  CommitmentInitiate = '/api/commitment/initiate/',
  CommitmentNext = '/api/commitment/next_action/',
  CommitmentOptions = '/api/commitment/options/',
  CommitmentResult = '/api/attempt/outcome/',
  CommitmentSubmitAnswer = '/api/commitment/submit_answer_and_next_action/',
  CommitmentInitiateSpecial = '/api/commitment/initiate_special/',
  CommitmentSubmitAnswerAndNextAction = '/api/commitment/submit_attempt_outcome_and_next_action/',

  SupplierLogIn = '/api/supplier/login_and_commitment/',
  SupplierSignUp = '/api/supplier/signup_and_commitment/',

  SupplierStats = '/api/supplier_dashboard/stats/',

  AffiliateTrackEvent = '/api/track/event/',

  OnboardingState = '/api/user/state/',

  ResearchDefenderToken = '/api/track/meta/',

  // TODO: remove
  TEMP_ResetOnboardingState = '/api/user/state/reset/',
  TEMP_AdvanceOnboardingState = '/api/user/state/next/',
  TEMP_CommitmentDummyData = '/api/dummy_data/',
  TEMP_Profiler_Data = '/api/get_profiler/',
}
