import { APIRoutes } from 'constants/';
import {
  Profiler,
  SurveyHistoryItem,
  CommitmentOption,
  Commitment,
  CommitmentActionTypes,
  AnswerValue,
} from 'types/';
import { baseApi, OnboardingTags, SurveyTags, UserTags } from './baseApi';

export interface CommitmentInitiatePayload {
  commitment_option_id: string;
}

export interface CommitmentNextActionPayload {
  id: string;
}

export interface CommitmentResultPayload {
  url: string;
}

export interface ProfilerQuestionPayload {
  id?: string;
}

export interface CommitmentAnswerPayload {
  id: string;
  profiler_id: number;
  answer: AnswerValue;
  other?: string;
}

export interface SupplierLogInPayload {
  url: string;
}

export interface SupplierSignUpPayload extends SupplierLogInPayload {
  agree_termsofservice: boolean;
  agree_privacypolicy: boolean;
  agree_cookiepolicy: boolean;
}

export interface SupplierStatsPayload {
  date_group: string;
  supplier_id: string;
  entries: number;
  completes: number;
  conversion: number;
  throttled: number;
  terminated: number;
  blacklisted: number;
  earnings: number;
  epc: number;
  cpi: number;
  loi: number;
}

export const surveysApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    surveyHistory: build.query<SurveyHistoryItem[], void>({
      query: () => ({
        url: APIRoutes.SurveysHistory,
        method: 'GET',
      }),
    }),
    commitmentOptions: build.query<CommitmentOption[], void>({
      query: () => ({
        url: APIRoutes.CommitmentOptions,
        method: 'GET',
      }),
      providesTags: [SurveyTags.Options],
      keepUnusedDataFor: 5,
    }),
    commitmentNextAction: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentNextActionPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentNext,
        method: 'POST',
        body,
      }),
      invalidatesTags: [UserTags.UserBasic, UserTags.UserFull],
    }),
    commitmentInitiate: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentInitiatePayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentInitiate,
        method: 'POST',
        body,
      }),
      invalidatesTags: [OnboardingTags.UserState, SurveyTags.Options],
    }),
    commitmentSpecialInitiate: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentInitiatePayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentInitiateSpecial,
        method: 'POST',
        body,
      }),
      invalidatesTags: [OnboardingTags.UserState],
    }),
    commitmentDummyData: build.query<void, void>({
      query: () => ({
        url: APIRoutes.TEMP_CommitmentDummyData,
        method: 'GET',
      }),
    }),
    commitmentResult: build.mutation<void, CommitmentResultPayload>({
      query: (body) => ({
        url: APIRoutes.CommitmentResult,
        method: 'POST',
        body,
      }),
    }),
    commitmentAnswer: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentAnswerPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentSubmitAnswer,
        method: 'POST',
        body,
      }),
    }),
    commitmentAnswerAndNextAction: build.mutation<
      Commitment<CommitmentActionTypes>,
      CommitmentResultPayload
    >({
      query: (body) => ({
        url: APIRoutes.CommitmentSubmitAnswerAndNextAction,
        method: 'POST',
        body,
      }),
    }),
    getProfilerQuestion: build.query<Profiler, ProfilerQuestionPayload | void>({
      query: (body) => ({
        url: `${APIRoutes.TEMP_Profiler_Data}${
          body?.id ? `?id=${body.id}` : ''
        }`,
        method: 'GET',
      }),
    }),
    supplierLogIn: build.mutation<
      Commitment<CommitmentActionTypes>,
      SupplierLogInPayload
    >({
      query: (body) => ({
        url: APIRoutes.SupplierLogIn,
        method: 'POST',
        body,
      }),
    }),
    supplierSignUp: build.mutation<
      Commitment<CommitmentActionTypes>,
      SupplierSignUpPayload
    >({
      query: (body) => ({
        url: APIRoutes.SupplierSignUp,
        method: 'POST',
        body,
      }),
    }),
    supplierStats: build.query<SupplierStatsPayload[], void>({
      query: () => ({
        url: APIRoutes.SupplierStats,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSurveyHistoryQuery,
  useCommitmentDummyDataQuery,
  useCommitmentInitiateMutation,
  useCommitmentSpecialInitiateMutation,
  useCommitmentNextActionMutation,
  useCommitmentOptionsQuery,
  useCommitmentResultMutation,
  useCommitmentAnswerMutation,
  useCommitmentAnswerAndNextActionMutation,
  useSupplierLogInMutation,
  useSupplierSignUpMutation,
  useSupplierStatsQuery,
  useLazyCommitmentDummyDataQuery,
  useLazyGetProfilerQuestionQuery,
} = surveysApi;
