import { SerializedError } from '@reduxjs/toolkit';
import { t } from '@lingui/macro';
import { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from 'assets/Logo.png';
import LogoHorizontal from 'assets/LogoHorizontal.png';
import {
  Dashboard,
  Performance,
  Settings,
  Payouts,
  LogOut,
  ContactUs,
  Menu,
} from 'assets/';
import { EventIds, Routes } from 'constants/';
import { openGenericErrorNotification } from 'helpers/';
import { useLogOutMutation } from 'store/api';
import { useAppSelector, useAppDispatch } from 'store/';
import { getAuthStore, logout } from 'store/slices';
import { useBreakpoint } from 'hooks/';
import { log } from 'utils/logService';
import {
  ContactUsModal,
  ContactUsSuccessModal,
  useContactUsModal,
  withContactUsModalContext,
  withContactUsSuccessModalContext,
} from 'components/ContactUs';
import {
  MobileNavbar,
  withMobileNavbarContext,
  useMobileNavbar,
} from './components';
import {
  Sidebar,
  ItemContent,
  LogoContainer,
  MenuLinkItem,
  MenuButtonItem,
  ItemName,
  TopBar,
  MenuButton,
} from './styled';

const NavbarInner = () => {
  const isMobile = useBreakpoint('lg');
  const dispatch = useAppDispatch();

  const { isLoggedIn } = useAppSelector(getAuthStore);

  const history = useHistory();
  const { location } = history;

  const { open: openMobileNavbar } = useMobileNavbar();

  const { open: openContactUsModal } = useContactUsModal();

  const [logOut] = useLogOutMutation();

  const handleContactUsClick = () => {
    openContactUsModal();
  };

  const handleLogOutClick = () => {
    logOut().then((result) => {
      const { error } = result as { error: SerializedError };
      if (error) {
        log.error(
          'Mutation to log out has failed',
          EventIds.AuthLogOutMutationError,
          error
        );
        openGenericErrorNotification();
        return;
      }

      document.cookie = document.cookie.replace(
        /csrftoken=\w+/,
        'csrftoken=""'
      );
      dispatch(logout());
      history.push(Routes.SignIn);
    });
  };

  const menuConfig = useMemo(
    () => [
      {
        icon: <Dashboard />,
        title: t`Dashboard`,
        path: Routes.Dashboard,
      },
      {
        icon: <Payouts />,
        title: t`Payouts`,
        path: Routes.Payouts,
      },
      {
        icon: <Performance />,
        title: t`Performance`,
        path: Routes.Performance,
      },
      {
        icon: <ContactUs />,
        title: t`Contact Us`,
        path: Routes.ContactUs,
        onClick: handleContactUsClick,
      },
      {
        icon: <Settings />,
        title: t`Settings`,
        path: Routes.Settings,
      },
      {
        icon: <LogOut />,
        title: t`Sign Out`,
        onClick: handleLogOutClick,
      },
    ],
    [handleContactUsClick, handleLogOutClick]
  );

  const getActiveLink = (path: Routes) => {
    const pathNames = location.pathname.split('/').filter((item) => !!item);

    return pathNames[0] === path.replace('/', '');
  };

  if (isMobile) {
    return (
      <TopBar>
        <Link to={Routes.Dashboard}>
          <img src={LogoHorizontal} alt={t`Cherries`} />
        </Link>
        <MenuButton type="text" icon={<Menu />} onClick={openMobileNavbar} />
        <MobileNavbar menuConfig={menuConfig} />
      </TopBar>
    );
  }

  const isActive = (path: string) =>
    getActiveLink((path as Routes) || Routes.Dashboard);

  return (
    <Sidebar>
      <LogoContainer to={Routes.Dashboard}>
        <ItemContent>
          <img src={Logo} alt={t`Cherries`} />
        </ItemContent>
      </LogoContainer>
      {menuConfig.map(({ icon, title, path, onClick }) =>
        onClick ? (
          <MenuButtonItem key={title} onClick={onClick}>
            <ItemContent>
              {icon}
              <ItemName>{title}</ItemName>
            </ItemContent>
          </MenuButtonItem>
        ) : (
          <MenuLinkItem
            $isActive={isActive(path)}
            key={title}
            to={path || Routes.Dashboard}
          >
            <ItemContent $isActive={isActive(path)}>
              {icon}
              <ItemName $isActive={isActive(path)}>{title}</ItemName>
            </ItemContent>
          </MenuLinkItem>
        )
      )}
      {isLoggedIn && (
        <>
          <ContactUsModal />
          <ContactUsSuccessModal />
        </>
      )}
    </Sidebar>
  );
};

export const Navbar = withContactUsModalContext(
  withContactUsSuccessModalContext(withMobileNavbarContext(NavbarInner))
);
