import { APIRoutes } from 'constants/';
import { baseApi } from './baseApi';

export interface LogInPayload {
  email: string;
  password: string;
}

export interface SignUpPayload extends LogInPayload {
  first_name?: string;
  last_name?: string;
  zip_code?: string;
  country?: string;
  date_of_birth?: string;
  gender?: 'male' | 'female' | 'non-binary' | 'undisclosed';
  url: string;
  agree_termsofservice?: boolean;
  agree_privacypolicy?: boolean;
  agree_cookiepolicy?: boolean;
}

export interface SignUpConfig {
  required: Array<keyof SignUpPayload>;
}

export interface PasswordResetPayload {
  email: string;
  reset_password_token: string;
  new_password: string;
}

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    logIn: build.mutation<void, LogInPayload>({
      query: (body) => ({
        url: APIRoutes.LogIn,
        method: 'POST',
        body,
      }),
    }),
    logOut: build.mutation<void, void>({
      query: () => ({
        url: APIRoutes.LogOut,
        method: 'POST',
      }),
    }),
    signUp: build.mutation<void, SignUpPayload>({
      query: (body) => ({
        url: APIRoutes.SingUp,
        method: 'POST',
        body,
      }),
    }),
    signUpConfig: build.query<SignUpConfig, void>({
      query: () => ({
        url: APIRoutes.SingUp,
        method: 'GET',
      }),
    }),
    initiateResetPassword: build.mutation<void, Pick<LogInPayload, 'email'>>({
      query: (body) => ({
        url: APIRoutes.ResetPasswordInitiate,
        method: 'POST',
        body,
      }),
    }),
    resetPassword: build.mutation<void, PasswordResetPayload>({
      query: (body) => ({
        url: APIRoutes.ResetPasswordSubmit,
        method: 'POST',
        body,
      }),
    }),
    refresh: build.mutation<void, void>({
      query: () => ({
        url: APIRoutes.RefreshTokens,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLogInMutation,
  useLogOutMutation,
  useSignUpMutation,
  useInitiateResetPasswordMutation,
  useResetPasswordMutation,
  useRefreshMutation,
  useSignUpConfigQuery,
} = authApi;
