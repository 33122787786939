import { AlertProps } from 'antd';
import { ErrorIcon } from 'assets';
import { StyledAlert } from './styled';

export const Alert = ({ ...props }: AlertProps) => {
  const isError = props.type === 'error';
  return (
    <StyledAlert
      showIcon
      icon={isError && <ErrorIcon />}
      $isError={isError}
      {...props}
    />
  );
};
